<template>
  <!-- eslint-disable  -->
  <div>
    <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
      {{ $store.getters['app/getRouteTitle'] }}
      <v-spacer></v-spacer>
    </div>

    <v-card
      outlined
      :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
    >
      <v-card-text class="d-flex align-center flex-wrap pb-0 pb-sm-4">
        <v-dialog v-if="$vuetify.breakpoint.smAndDown">
          <template #activator="{ on }">
            <v-icon v-on="on">mdi-information-outline</v-icon>
          </template>
          <v-card>
            <v-card-text class="py-3">
              可提現金額： {{ merchant_info.amt || 0 }} 元
              <br />
              凍結資金： {{ merchant_info.frozen_amt || 0 }} 元
              <br />
              單筆最低提現： {{ merchant_info.single_min_amt || 0 }} 元
              <br />
              單筆最高提現： {{ merchant_info.single_max_amt || 0 }} 元
              <br />
              單筆手續費： {{ merchant_info.fee || 0 }} 元
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" v-bind="attrs" v-on="on" class="ml-2">
                    mdi-alert-circle
                  </v-icon>
                </template>
                <span>備註：手續費將從餘額裡扣除</span>
              </v-tooltip>
            </v-card-text>
          </v-card>
        </v-dialog>
        <div v-else class="d-flex align-center flex-wrap mb-4 mb-sm-0">
          可提現金額： {{ merchant_info.amt || 0 }} 元
          <v-divider vertical class="mx-3"></v-divider>
          凍結資金： {{ merchant_info.frozen_amt || 0 }} 元
          <v-divider vertical class="mx-3"></v-divider>
          單筆最低提現： {{ merchant_info.single_min_amt || 0 }} 元
          <v-divider vertical class="mx-3"></v-divider>
          單筆最高提現： {{ merchant_info.single_max_amt || 0 }} 元
          <v-divider vertical class="mx-3"></v-divider>
          單筆手續費： {{ merchant_info.fee || 0 }} 元
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on" class="ml-2">
                mdi-alert-circle
              </v-icon>
            </template>
            <span>備註：手續費將從餘額裡扣除</span>
          </v-tooltip>
        </div>
        <v-divider vertical class="mx-3"></v-divider>
        <v-spacer></v-spacer>
      </v-card-text>

      <v-card-text>
        <v-card outlined>
          <div class="pa-2 pa-sm-4">
            <v-slide-x-transition group>
              <PlaceOrderRow
                ref="PlaceOrderRow"
                v-for="(order, i) in orders"
                :key="`order${order.id}`"
                :index="i + 1"
                :merchantInfo="merchant_info"
                :payPassword="payPassword"
                :otpCode="otpCode"
                @delete="orders.splice(i, 1)"
                @loading="(v) => $set(order, 'loading', v)"
              />
            </v-slide-x-transition>

            <SizeBox height="24" />
            <v-btn rounded color="Secondary050" depressed @click="addOrder">
              <v-icon>mdi-pencil-outline</v-icon>
              新增一筆
            </v-btn>
          </div>

          <v-expand-transition>
            <v-card-text v-if="orders.length">
              <v-card
                outlined
                :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
              >
                <v-card-title class="Surface050">密碼與驗證碼</v-card-title>
                <v-card-text>
                  <v-form ref="Secrets">
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          rounded
                          outlined
                          dense
                          label="支付密碼"
                          :rules="[$rules.required]"
                          v-model="payPassword"
                          :type="showPassword ? 'text' : 'password'"
                          :append-icon="
                            showPassword ? 'mdi-eye' : 'mdi-eye-off'
                          "
                          @click:append="showPassword = !showPassword"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          rounded
                          outlined
                          dense
                          label="谷歌驗證碼"
                          :rules="[$rules.required]"
                          v-model="otpCode"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions class="flex-center">
                  <v-btn
                    rounded
                    width="320"
                    color="primary"
                    @click="submit"
                    :loading="isLoading"
                  >
                    提交
                  </v-btn>
                </v-card-actions>
                <SizeBox height="24" />
              </v-card>
            </v-card-text>
          </v-expand-transition>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { getMerchantPayOrderInfo } from '@/api/merchantCenter/payOrders';
import PlaceOrderRow from '@/components/MerchantPages/pay/request/PlaceOrderRow.vue';

export default {
  components: { PlaceOrderRow },

  data() {
    return {
      showPassword: false,
      payPassword: '',
      otpCode: '',
      merchant_info: {
        amt: 0,
        fee: 0,
        fee_mode: 'fee',
        fee_percent: 0,
        frozen_amt: 0,
        single_max_amt: 0,
        single_min_amt: 0
      },

      orders: []
    };
  },

  computed: {
    isLoading() {
      return this.orders.some((order) => order.loading);
    }
  },

  created() {
    this.fetchData();
    if (!this.orders.length) this.addOrder();
  },

  methods: {
    async fetchData() {
      const Info = await getMerchantPayOrderInfo();

      if (!Info.error) {
        this.$set(this, 'merchant_info', Info);
      }
    },
    addOrder() {
      this.orders.push({
        id: Date.now()
      });
    },
    validateOrders() {
      let isError = false;
      this.$refs.PlaceOrderRow.forEach((el) => {
        !el.validate() && (isError = true);
      });

      return !isError;
    },
    submit() {
      if (!this.validateOrders() || !this.$refs.Secrets.validate()) return;
      this.$refs.PlaceOrderRow.forEach((el) => {
        el.send();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>

