<template>
  <v-tooltip top :disabled="isError ? false : !isSuccess" :value="isError">
    <template #activator="{ on }">
      <v-card v-on="on" outlined class="py-3 my-1" :color="cardColor">
        <v-list-item>
          <v-form ref="form">
            <v-row align="center" class="flex-sm-nowrap">
              <v-col cols="auto" class="px-0">#{{ index }}</v-col>
              <v-col
                v-if="$vuetify.breakpoint.smAndDown"
                cols="12"
                class="py-0"
              ></v-col>
              <v-col cols="6" sm="" class="px-sm-1">
                <v-text-field
                  rounded
                  label="訂單金額"
                  :rules="[$rules.requiredNum]"
                  v-model.number="form.amount"
                  outlined
                  dense
                  hide-details
                  :readonly="isSuccess"
                  @focus="cleanError"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="" class="px-sm-1">
                <v-text-field
                  rounded
                  label="手續費"
                  :value="merchantInfo.fee"
                  outlined
                  dense
                  hide-details
                  :readonly="isSuccess"
                  disabled
                  @focus="cleanError"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="" class="px-sm-1">
                <v-text-field
                  rounded
                  label="實際入帳"
                  :value="form.amount"
                  outlined
                  dense
                  hide-details
                  :readonly="isSuccess"
                  disabled
                  @focus="cleanError"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="" class="px-sm-1">
                <v-text-field
                  rounded
                  label="收款人"
                  :rules="[$rules.required]"
                  v-model="form.bank_holder"
                  outlined
                  dense
                  hide-details
                  :readonly="isSuccess"
                  @focus="cleanError"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="" class="px-sm-1">
                <v-text-field
                  rounded
                  label="收款帳號"
                  :rules="[$rules.required]"
                  v-model="form.bank_card_no"
                  outlined
                  dense
                  hide-details
                  :readonly="isSuccess"
                  @focus="cleanError"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="" class="px-sm-1">
                <v-text-field
                  rounded
                  label="銀行名稱"
                  :rules="[$rules.required]"
                  v-model="form.bank_name"
                  outlined
                  dense
                  hide-details
                  :readonly="isSuccess"
                  @focus="cleanError"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="" class="px-sm-1">
                <v-text-field
                  rounded
                  label="支行名稱"
                  v-model="form.bank_branch"
                  outlined
                  dense
                  hide-details
                  :readonly="isSuccess"
                  @focus="cleanError"
                ></v-text-field>
              </v-col>
              <v-col cols="auto" class="px-0">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  v-if="isLoading"
                  size="24"
                ></v-progress-circular>
                <v-icon v-else-if="isSuccess" @click.stop="$emit('delete')">
                  mdi-close
                </v-icon>
                <v-icon v-else @click.stop="$emit('delete')">
                  mdi-trash-can-outline
                </v-icon>
              </v-col>
            </v-row>
          </v-form>
        </v-list-item>
      </v-card>
    </template>
    <span>
      {{ hint }}
    </span>
  </v-tooltip>
</template>

<script>
import { placeOrder } from '@/api/merchantCenter/payOrders';

export default {
  props: {
    index: {
      type: Number,
      default: 0
    },
    merchantInfo: {
      type: Object,
      default: () => ({})
    },
    payPassword: { type: String, default: '' },
    otpCode: { type: String, default: '' }
  },

  data() {
    return {
      isError: false,
      isLoading: false,
      isSuccess: false,
      hint: '',
      form: {
        amount: 0,
        bank_holder: '',
        bank_card_no: '',
        bank_name: '',
        bank_branch: ''
      }
    };
  },

  computed: {
    cardColor() {
      if (this.isError) return 'Error300';
      if (this.isSuccess) return '#14af9d44';
      return null;
    }
  },

  watch: {
    isLoading(v) {
      this.$emit('loading', v);
    }
  },

  methods: {
    /* exposed method */
    validate() {
      if (this.isSuccess) return true;
      const result = this.$refs.form.validate();
      if (!result) {
        this.isError = true;
      }
      return result;
    },

    cleanError() {
      if (this.isError) {
        this.$refs.form.resetValidation();
        this.isError = false;
      }
    },

    /* exposed method */
    async send() {
      if (this.isSuccess) return;
      this.cleanError();
      this.isLoading = true;

      const { success, error } = await placeOrder({
        ...this.form,
        pay_password: this.payPassword,
        otp_code: this.otpCode
      });

      if (success) {
        this.isSuccess = true;
        this.hint = '已完成';
      } else {
        this.isError = true;
        const detail = error.detail || {};
        const errorMsg =
          detail.result && detail.message
            ? `${detail.result}:${detail.message}`
            : '提現失敗';
        this.hint = errorMsg;
      }

      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>